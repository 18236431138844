import React, { useContext } from 'react'
import './cart.scss'
import creambottom from "../../images/cream_bottom_wave_01.png"
import { IoMdClose } from "react-icons/io";
import { CartContext } from '../../App';
import { Link } from 'react-router-dom';
import { IoMdInformationCircle } from "react-icons/io";

const Cart = () => {
  const { cart, removeFromCart, total, updateItemCount } = useContext(CartContext);

  // const cartItemCount = cart.reduce((acc, item) => {
  //   if (!acc[item.name]) {
  //     acc[item.name] = 0;
  //   }
  //   acc[item.name]++;
  //   return acc;
  // }, {});

  return (
    <section className="cart">
      <div className="cart__hero">
        <div className="cart__title">
          <div>
            <h1>Your Shopping Cart</h1>
            <p className='text'>Start your order and enjoy the tastiest burgers</p>
          </div>
        </div>
        <img src={creambottom} className="creambottom" alt="" />
      </div>
      <div className="cart-products-desktop">
        <div className="wrapper-container">
          <div className="product-details">
            <table>
              {cart.length > 0 ? (
                <>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>

                  {/* {Object.entries(cartItemCount).map(([itemName, itemCount], index) => (
                    <>
                      <tr key={index}>
                        <td><IoMdClose onClick={() => removeFromCart(itemName)} /></td>
                        <td><img src={cart.find(item => item.name === itemName)?.image} alt={itemName} /></td>
                        <td>{itemName}</td>
                        <td>${(cart.find(item => item.name === itemName)?.price).toFixed(2)}</td>
                        <td><input type="number" value={itemCount} /></td>
                        <td>${itemCount * (cart.find(item => item.name === itemName)?.price).toFixed(2)}</td>
                      </tr>
                    </>
                  ))} */}
                  <tbody>
                    {cart.map((item, index) => (
                      <tr key={index}>
                        <td><IoMdClose onClick={() => removeFromCart(item.name)} /></td>
                        <td><img src={item.image} alt={item.name} /></td>
                        <td>{item.name}</td>
                        <td>${item.price.toFixed(2)}</td>
                        <td>
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) => {
                              const newCount = parseInt(e.target.value);
                              // Ellenőrizd, hogy az új érték nem lehet kisebb vagy egyenlő 0-nál
                              if (newCount > 0) {
                                updateItemCount(item.name, newCount);
                              } else {
                                // Ha az új érték 0 vagy kisebb, akkor ne változtassunk meg semmit
                                // Itt opcionálisan jelezhetnénk a felhasználónak is, hogy az érték legalább 1 legyen
                                console.log('Az érték legalább 1-nek kell lennie.');
                              }
                            }}
                          />
                        </td>
                        <td>${(item.quantity * item.price).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </>
              ) : (
                <div className='no-product'>
                  <div className='information'>
                    <IoMdInformationCircle />
                    <span>Your cart is currently empty.</span>
                  </div>
                  <Link to='/'>Return to shop</Link>
                </div>
              )}
            </table>
            {cart.length > 0 ? (
              <div className='cart-totals'>
                <h2>Cart totals</h2>
                <div className='details'>
                  <div className='flex'>
                    <p>Shipping</p>
                    <p>Free shipping</p>
                  </div>
                  <div className='flex'>
                    <p>Total</p>
                    {/* <p className='price'>$27.00</p> */}
                    <p className='price'>${total.toFixed(2)}</p>
                  </div>
                </div>
                <div className='text-center'>
                  <Link to='/checkout'>Proceed to checkout</Link>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      <div className="cart-products-mobile">
        <div className="wrapper-container-mobile">
          <div className="product-details-mobile">
            <div>
              {cart.length > 0 ? (
                <div className="table-mobile">
                  {/* {Object.entries(cartItemCount).map(([itemName, itemCount], index) => (
                    <div className='product-mobile' key={index}>
                      <IoMdClose onClick={() => removeFromCart(itemName)} />
                      <img src={cart.find(item => item.name === itemName)?.image} alt={itemName} />
                      <div><strong>Product: </strong><span>{itemName}</span></div>
                      <div><strong>Price: </strong>${(cart.find(item => item.name === itemName)?.price).toFixed(2)}</div>
                      <div><strong>Quantity: </strong><input type="number" value={itemCount} /></div>
                      <div><strong>Subtotal: </strong>${(itemCount * (cart.find(item => item.name === itemName)?.price)).toFixed(2)}</div>
                    </div>
                  ))} */}
                  {cart.map((item, index) => (
                    <div className="product-mobile" key={index}>
                      <IoMdClose onClick={() => removeFromCart(item.name)} />
                      <img src={item.image} alt={item.name} />
                      <div><strong>Price: </strong>${item.price.toFixed(2)}</div>
                      <div><strong>Quantity: </strong><input type="number" value={item.quantity} onChange={(e) => {
                        const newCount = parseInt(e.target.value);
                        if (newCount > 0) {
                          updateItemCount(item.name, newCount);
                        } else {
                          console.log('Az érték legalább 1-nek kell lennie.');
                        }
                      }} /></div>
                      <div><strong>Subtotal: </strong>${(item.quantity * item.price).toFixed(2)}</div>
                    </div>
                  ))}
                  <h2>Cart totals</h2>
                  <div className="shipping-mobile">
                    <p>Shipping</p>
                    <p>Free Shipping</p>
                  </div>
                  <div className="total-mobile">
                    <p>Total</p>
                    <strong>${total.toFixed(2)}</strong>
                  </div>
                  <div className='to-checkout'>
                    <Link to='/checkout'>Proceed to checkout</Link>
                  </div>
                </div>
              ) : (
                <div className="no-product">
                  <div className="cart-products-mobile">
                    <div className="wrapper-container-mobile">
                      <div className="product-details-mobile">
                        <h1>No product in the cart</h1>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Cart