import React, { useState, useEffect } from 'react';
import './slider.scss';
import redtop from "../../images/red_top_wave_01.png";
import creambottom from "../../images/cream_bottom_wave_01.png";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import slider1 from '../../images/home_slider_01.jpg';
import slider2 from '../../images/home_slider_02.jpg';
import slider3 from '../../images/home_slider_03.jpg';
import logo2 from '../../images/image_logo_02.png';

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { image: slider1, title: "World Famous Burger", content: "100 % Grass-fed beef patty, cheddar cheese, special sauce, tomato, pickles, lettuce, sesame seed bun" },
        { image: slider2, title: "Popular ShroomBacon", content: "100 % Grass-fed beef patty, cheddar cheese, special sauce, tomato, pickles, lettuce, sesame seed bun" },
        { image: slider3, title: "Best Local TunaBurger", content: "100 % Grass-fed beef patty, cheddar cheese, special sauce, tomato, pickles, lettuce, sesame seed bun" },
    ];

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 7000); 

        return () => clearInterval(interval);
    }, [currentSlide]);

    return (
        <section className='slider'>
            <div className='slides-container' style={{ width: `${slides.length * 100}%`, transform: `translateX(-${currentSlide * (100 / slides.length)}%)` }}>
                {slides.map((slide, index) => (
                    <div key={index} className='slide' style={{ width: `calc(100% / ${slides.length})`, backgroundImage: `url(${slide.image})` }}>
                        <img className="redtop" alt="" src={redtop} />
                        <div className="slide-content">
                            <h2>{slide.title}</h2>
                            <div className='text-image'>
                                <img src={logo2} alt="" />
                                <p>{slide.content}</p>
                            </div>
                        </div>
                        <img className='creambottom' alt="" src={creambottom} />
                    </div>
                ))}
            </div>
            <div className="slider-buttons">
                <button className="slider-button left" onClick={prevSlide}><BsChevronLeft /></button>
                <button className="slider-button right" onClick={nextSlide}><BsChevronRight /></button>
            </div>
            <div className="dots">
                {slides.map((slide, index) => (
                    <div key={index} className={`dot ${index === currentSlide ? 'active' : ''}`} onClick={() => setCurrentSlide(index)}></div>
                ))}
            </div>
        </section>
    )
}

export default Slider;
