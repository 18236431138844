import React from 'react'
import './about.scss'
import burger from '../../images/burger.png'
import aboutus1 from '../../images/aboutus1.jpg'
import aboutus2 from '../../images/aboutus2.jpg'
import aboutus3 from '../../images/aboutus3.jpg'
import motorcycle from '../../images/motorcycle.png'
import aboutbasket from '../../images/about__basket.png'
import redtop from '../../images/red_top_wave_01.png'
import redbottom from '../../images/red_bottom.png'
import { FaRegCircleCheck } from "react-icons/fa6";

const About = () => {
  return (<>
    <section className="about">
      <div className="about__hero">
        <div className="hero__title">
          <div>
            <h1>Crazy good burgers</h1>
            <p>We strive to elevate the burger experience</p>
          </div>
        </div>
        <img src={redbottom} className="redbottom" alt="" />
      </div>
      <div className="flipping">
        <h2>Flipping Since '94</h2>
        <div className="flipping-container">
          <div className="row">
            <div className="col-33">
              <div className='card'>
                <img src={burger} alt="" />
                <h3>Unique Taste</h3>
                <p>A burger is more than just a, it is a wish fulfilled.</p>
              </div>
            </div>
            <div className="col-33">
              <div className='card'>
                <img src={aboutbasket} alt="" />
                <h3>Certified Farm</h3>
                <p>The last time you had a burger was too long ago.</p>
              </div>
            </div>
            <div className="col-33">
              <div className='card'>
                <img src={motorcycle} alt="" />
                <h3>Home Delivery</h3>
                <p>Why are you still reading this - go get a burger!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about__us'>
        <img src={redtop} className='redtop' alt="" />
        <h1>About Us</h1>
        <div className="big__card">
          <div className='flex'>
            <img src={aboutus1} alt="" />
          </div>
          <div className='desc'>
            <div>
              <h3>Our<br /> Philosophy</h3>
              <div className='flex'>
                <p className='strong'>A relaxed burger joint, utilising local ingredients to make delicious burgers, sides, and more</p>
                <div>
                  <p>We are lucky to live in a glorious age that gives us everything we could ask for as a human race. What more could you need when you have meat covered in cheese nestled between bread.</p>
                  <p>From smashed patties at Shake Shack to Glamburgers at Honky Tonk, there’s a little something for everyone. Some burgers are humble, and some are ostentatious, and you just have to try them all to figure out what you want. Feel free to contact us.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="big__card">
          <div className='flex'>
            <img src={aboutus2} alt="" />
          </div>
          <div className='desc'>
            <div>
              <h3>Local <br /> ingredients</h3>
              <div className='flex'>
                <p className='strong'>We strive to elevate the burger experience to its top shelf – because you and your burger deserve better eating style</p>
                <div>
                  <p>Whether you’re a ketchup and mustard loyalist or you prefer house sauce to no sauce, you can agree that every cheeseburger has it’s own special flair. Top tip: house sauce begs to be ordered on the side for fry-dipping purposes.</p>
                  <p>The cheese melts on the burger and in your mouth, perfectly complementing the medium-rare beef. Any burger lover worth their salt knows the best patty is comprised of ground chuck and brisket.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="big__card">
          <div className='flex'>
            <img src={aboutus3} alt="" />
          </div>
          <div className='desc'>
            <div>
              <h3>Delivery or <br /> Takeaway</h3>
              <div className='flex'>
                <p className='strong'>We’ve made things doubly easy for you by offering takeaway options as well as door-to-door delivery every day</p>
                <div>
                  <p>We are lucky to live in a glorious age that gives us everything we could ask for as a human race. What more could you need when you have meat covered in cheese nestled between bread.</p>
                  <ul>
                    <li><FaRegCircleCheck /> Click & Collect</li>
                    <li><FaRegCircleCheck /> Takeout Available</li>
                    <li><FaRegCircleCheck /> Home Delivery</li>
                    <li><FaRegCircleCheck /> App Order & Delivery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default About