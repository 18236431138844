import './follows.scss'
import redtop from "../../images/red_top_wave_01.png"
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import creambottom from '../../images/cream_bottom_wave_01.png'
import creamtop from '../../images/cream_top_wave_01.png'
import redbottom from '../../images/red_bottom.png'

const Follows = () => {

    const location = useLocation();
    const [topImage, setTopImage] = useState(creamtop);
    const [bottomImage, setBottomImage] = useState(creambottom);


    useEffect(() => {
        if (location.pathname === '/contact') {
            setTopImage(creamtop);
            setBottomImage(redbottom);
        } else {
            setTopImage(redtop);
            setBottomImage(creambottom);
        }
    }, [location.pathname]);

    return (
        <section className='follows'>
            <img className='redtop' src={topImage} alt="" />
            <div className='social'>
                <div className='giveUsAFollow'>
                    <div>
                        <h5>Give us a follow</h5>
                    </div>
                    <div className='socialIcons'>
                        <Link to='https://www.facebook.com/' target="_blank"><FaFacebookF /></Link>
                        <Link to='https://twitter.com/' target="_blank"><FaTwitter /></Link>
                        <Link to='https://www.instagram.com/' target="_blank"><FaInstagram /></Link>
                    </div>
                </div>
            </div>
            <img src={bottomImage} className='bottom-img' alt="" />
        </section>
    )
}

export default Follows