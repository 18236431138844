import React, { useContext, useEffect, useState } from 'react';
import './header.scss';
import Logo from '../../images/logo.png';
import { BsBasket3Fill } from 'react-icons/bs';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import { CartContext } from '../../App';
import { IoMdClose } from "react-icons/io";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Kezdetben a mobilmenü zárva van
  const location = useLocation();
  const { cart, removeFromCart, total } = useContext(CartContext); // Importáljuk be a removeFromCart és total változókat

  // Kosárban lévő összes darabszám kiszámítása
  const totalCartItems = cart.reduce((acc, item) => acc + item.quantity, 0);

  useEffect(() => {
    setIsActive(false); // Az útvonalváltozásra bezárjuk a dropdown-ot
  }, [location.pathname]);

  return (
    <>
      <header className='header__desktop'>
        <div className='navmenu'>
          <Link to="/"><img className='logo' src={Logo} alt="Logo" /></Link>
          <ul>
            <li><NavLink to='/'>Home</NavLink></li>
            <li><NavLink to='about'>About</NavLink></li>
            <li><NavLink to='shop'>Shop</NavLink></li>
          </ul>
        </div>
        <div className='top-bar'>
          <div className='order'>
            <p>Order by phone</p>
            <p>1-800-700-600</p>
          </div>
          {location.pathname !== '/cart' && location.pathname !== '/checkout' && (
            <div className='quantity' onClick={() => setIsActive(current => !current)}>
              <BsBasket3Fill className='basket' /> <span className='cart-quantity'>{totalCartItems}</span>
            </div>
          )}
          <div>
            <Link to="/shop">View full menu</Link>
          </div>
        </div>
        <div className={isActive ? 'dropdownOpen' : 'dropdown'}>
          {cart.length > 0 ? (
            <>
              <ul>
                {cart.map((item, index) => (
                  <li key={index}>
                    <div className="cart-item">
                      <img src={item.image} alt={item.name} className="cart-item-image" />
                      <div className="cart-item-details">
                        <p className='item-name'>{item.name}</p>
                        <p className='item-quantity-price'>{item.quantity} x ${(item.price).toFixed(2)}</p>
                      </div>
                      <button onClick={() => removeFromCart(item.name)}><IoMdClose /></button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="subtotal">
                <p>Subtotal:</p>
                <p className='total-amount'>${total}</p>
              </div>
            </>
          ) : (
            <p>No product in the cart</p>
          )}
          {cart.length > 0 && (
            <div className='buttons'>
              <Link to='cart'>View cart</Link>
              <Link to='checkout'>Checkout</Link>
            </div>
          )}
        </div>
      </header>
      <header className='header__mobile'>
        <RxHamburgerMenu className='hamburger' onClick={() => setIsOpen(!isOpen)} />
        <Link to="/" onClick={() => setIsOpen(false)}><img className='logo' src={Logo} alt="Logo" /></Link>
        <div className={`mobile__menu ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to='/' onClick={() => setIsOpen(false)} className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
            <li><Link to='/about' onClick={() => setIsOpen(false)} className={location.pathname === '/about' ? 'active' : ''}>About</Link></li>
            <li><Link to='/shop' onClick={() => setIsOpen(false)} className={location.pathname === '/shop' ? 'active' : ''}>Shop</Link></li>
          </ul>
          <div className="order">
            <p>Order by phone</p>
            <p>1-800-700-600</p>
          </div>
          <div className='quantity__container'>
            <Link to='/shop' onClick={() => setIsOpen(false)}>
              <div className='quantity'>
                <BsBasket3Fill className='basket' /> <span>{totalCartItems}</span>
              </div>
            </Link>
          </div>
          <div>
            <Link className='full__menu' to="/shop" onClick={() => setIsOpen(false)}>View full menu</Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
