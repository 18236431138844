import React from 'react'
import './availability.scss'

const Availability = () => {
    return (
        <section className='availability'>
            <div className="wrapper-container">
                <div className="row">
                    <div className="col-50">
                        <h2>Contactless delivery available</h2>
                        <div>
                            <span>Order by phone</span>
                            <p className='phone-number'>1-800-700-600</p>
                        </div>
                        <div className="row">
                            <div className="col-33">
                                <h6>Location</h6>
                                <p>60 East 65th Street, New York</p>
                            </div>
                            <div className="col-33">
                                <h6>Delivery hours</h6>
                                <p>
                                    <span>Monday – Saturday:</span> <br />
                                    11AM – 11PM
                                </p>
                                <p>
                                    <strong>Sunday:</strong> <br />
                                    11AM – 08PM
                                </p>
                            </div>
                            <div className="col-33">
                                <h6>Takeaway hours</h6>
                                <p>
                                    <span>Monday – Saturday:</span> <br />
                                    01PM – 10PM
                                </p>
                                <p><span>Sunday: </span>Closed</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-50">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2665.4930478456163!2d20.761563977045668!3d48.081427471237355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47409f84ee990555%3A0x5f1a1e1d57dd9208!2sMiskolci%20Egyetem!5e0!3m2!1sen!2shu!4v1700327974578!5m2!1sen!2shu" frameBorder="0" width="550" height="550" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Availability