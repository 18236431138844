import React, { useContext, useState } from 'react';
import './checkout.scss';
import creambottom from '../../images/cream_bottom_wave_01.png';
import { CartContext } from '../../App';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileSaver from 'file-saver';

const Checkout = () => {
  const { cart, clearCart, total } = useContext(CartContext);

  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('direct');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '', // Company field added
    country: '',
    postcode: '',
    town: '',
    streetAddress: '',
    phone: '',
    email: '',
    differentFirstName: '',
    differentLastName: '',
    differentCompany: '',
    differentCountry: '',
    differentPostcode: '',
    differentTown: '',
    differentStreetAddress: '',
    orderNotes: '', // Order notes field added
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      company: '',
      country: '',
      postcode: '',
      town: '',
      streetAddress: '',
      phone: '',
      email: '',
      differentFirstName: '',
      differentLastName: '',
      differentCompany: '',
      differentCountry: '',
      differentPostcode: '',
      differentTown: '',
      differentStreetAddress: '',
      orderNotes: '',
    });
  };

  const saveCartToJson = () => {
    const dataToSave = {
      cart: cart.map(item => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price, // Hozzáadva az egységár
        subtotal: (item.quantity * item.price).toFixed(2) // Összeg kiszámítása
      })),
      formData: { ...formData, total, paymentMethod: selectedOption }
    };

    const jsonData = JSON.stringify(dataToSave, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    FileSaver.saveAs(blob, 'cart_data.json');
  };

  const validateForm = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'country',
      'postcode',
      'town',
      'streetAddress',
      'phone',
      'email',
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill out ${field}`, { position: 'bottom-right' });
        return false;
      }
    }

    if (isChecked) {
      const differentRequiredFields = [
        'differentFirstName',
        'differentLastName',
        'differentCountry',
        'differentPostcode',
        'differentTown',
        'differentStreetAddress',
      ];

      for (const field of differentRequiredFields) {
        if (!formData[field]) {
          toast.error(`Please fill out ${field}`, { position: 'bottom-right' });
          return false;
        }
      }
    }

    return true;
  };

  const handleProceedToPaypal = (e) => {
    e.preventDefault();

    if (validateForm()) {
      saveCartToJson();
      clearCart();
      clearForm();
      toast.success('Order placed successfully!', { position: 'bottom-right' });
    }
  };

  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <section className="checkout">
      <div className="checkout__hero">
        <div className="checkout__title">
          <div>
            <h1>Check Your Burgers</h1>
            <p className="text">We guarantee delivery that arrives within an hour</p>
          </div>
        </div>
        <img src={creambottom} className="creambottom" alt="" />
      </div>
      <div className="checkout-products">
        <div className="wrapper-container">
          <div className="checkout-details">
            <form action="" onSubmit={handleProceedToPaypal}>
              <div className="row">
                <div className="col-50">
                  <h2>Billing details</h2>
                  <label htmlFor="firstName">First Name <span>*</span></label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name.."
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="lastName">Last Name <span>*</span></label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name.."
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="company">Company Name (optional)</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Company name.."
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="country">Country / Region <span>*</span></label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="postcode">Postcode / ZIP <span>*</span></label>
                  <input
                    type="text"
                    id="postcode"
                    name="postcode"
                    placeholder="Postcode..."
                    value={formData.postcode}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="town">Town / City <span>*</span></label>
                  <input
                    type="text"
                    id="town"
                    name="town"
                    placeholder="City or town..."
                    value={formData.town}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="streetAddress">Street address <span>*</span></label>
                  <input
                    type="text"
                    id="streetAddress"
                    name="streetAddress"
                    placeholder="Street address..."
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="phone">Phone <span>*</span></label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone number..."
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="email">Email address <span>*</span></label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email address..."
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-50">
                  <div className="shipto">
                    <div className="flex">
                      <input
                        type="checkbox"
                        className="check"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label className="container label-container">
                        <h2>Ship to different address?</h2>
                      </label>
                    </div>
                    <div className={`different-address ${isChecked ? 'visible' : ''}`}>
                      <label htmlFor="differentFirstName">First Name <span>*</span></label>
                      <input
                        type="text"
                        id="differentFirstName"
                        name="differentFirstName"
                        placeholder="First name.."
                        value={formData.differentFirstName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentLastName">Last Name <span>*</span></label>
                      <input
                        type="text"
                        id="differentLastName"
                        name="differentLastName"
                        placeholder="Last name.."
                        value={formData.differentLastName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentCompany">Company Name (optional)</label>
                      <input
                        type="text"
                        id="differentCompany"
                        name="differentCompany"
                        placeholder="Company name.."
                        value={formData.differentCompany}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentCountry">Country / Region <span>*</span></label>
                      <input
                        type="text"
                        id="differentCountry"
                        name="differentCountry"
                        placeholder="Country"
                        value={formData.differentCountry}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentPostcode">Postcode / ZIP <span>*</span></label>
                      <input
                        type="text"
                        id="differentPostcode"
                        name="differentPostcode"
                        placeholder="Postcode..."
                        value={formData.differentPostcode}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentTown">Town / City <span>*</span></label>
                      <input
                        type="text"
                        id="differentTown"
                        name="differentTown"
                        placeholder="City or town..."
                        value={formData.differentTown}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="differentStreetAddress">Street address <span>*</span></label>
                      <input
                        type="text"
                        id="differentStreetAddress"
                        name="differentStreetAddress"
                        placeholder="Street address"
                        value={formData.differentStreetAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                    <label htmlFor="orderNotes">Order notes (optional)</label>
                    <textarea
                      placeholder="Notes about your order, e.g. special notes for delivery"
                      name="orderNotes"
                      id="orderNotes"
                      cols="30"
                      rows="10"
                      value={formData.orderNotes}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <h3>Your order</h3>
              <div className="product-table">
                <div className="table-head">
                  <span>Product</span>
                  <span>Subtotal</span>
                </div>
                <div className="table-body">
                  {cart.map((item, index) => (
                    <div key={index} className="products">
                      <div className="cart-item">
                        <div className="cart-item-details">
                          <p className="item-name">
                            {item.name} <strong>x {item.quantity}</strong>
                          </p>
                          <p className="item-quantity-price">${item.price.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="total">
                    <p>Total:</p>
                    <p className="total-amount">${total.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div className="radiobuttons">
                <div className="flex">
                  <input
                    className="radiobutton"
                    type="radio"
                    id="direct"
                    value="direct"
                    name="paymentMethod"
                    onClick={() => handleRadioChange('direct')}
                    defaultChecked
                  />
                  <label htmlFor="direct">Direct bank transfer</label>
                </div>
                <div className="flex">
                  <input
                    className="radiobutton"
                    type="radio"
                    id="check"
                    value="check"
                    name="paymentMethod"
                    onClick={() => handleRadioChange('check')}
                  />
                  <label htmlFor="check">Check payments</label>
                </div>
                <div className="flex">
                  <input
                    className="radiobutton"
                    type="radio"
                    id="cash"
                    value="cash"
                    name="paymentMethod"
                    onClick={() => handleRadioChange('cash')}
                  />
                  <label htmlFor="cash">Cash on delivery</label>
                </div>
                <div className="flex">
                  <input
                    className="radiobutton"
                    type="radio"
                    id="paypal"
                    value="paypal"
                    name="paymentMethod"
                    onClick={() => handleRadioChange('paypal')}
                  />
                  <label htmlFor="paypal">PayPal</label>
                </div>
              </div>
              <div className="submit">
                <button type="submit">Proceed to PayPal</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </section>
  );
};

export default Checkout;
