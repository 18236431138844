import React from 'react'
import './review.scss';
import creamtop from "../../images/cream_top_wave_01.png"
import burger from '../../images/burger_02.png';
import reviews from "../../images/reviews_01.png"
import reviews2 from "../../images/reviews_02.png"
import reviews3 from "../../images/reviews_03.png"
import reviews4 from "../../images/reviews_04.png"

const Review = () => {
    return (
        <section className='review'>
            <img className='creamtop' src={creamtop} alt="" />
            <h1>Reviews</h1>
            <div className='center'>
                <img src={burger} className='burger' alt="burger" />
            </div>
            <div className="row w-100">
                <div className="col-50">
                    <img src={reviews} alt="" />
                </div>
                <div className="col-50">
                    <h2>"I don't remember a single mouthful I didn't enjoy!"</h2>
                    <p>@casey</p>
                </div>
            </div>
            <div className="row w-100">
                <div className="col-50">
                    <img src={reviews2} alt="" />
                </div>
                <div className="col-50">
                    <h2>"Pretty impressive! Legit taste of burgers!!!"</h2>
                    <p>@mark_twin</p>
                </div>
            </div>
            <div className="row w-100">
                <div className="col-50">
                    <img src={reviews3} alt="" />
                </div>
                <div className="col-50">
                    <h2>"Eatsy burgers are some of the most tastiest burgers I've had!"</h2>
                    <p>@sarah_composer</p>
                </div>
            </div>
            <div className="row w-100">
                <div className="col-50">
                    <img src={reviews4} alt="" />
                </div>
                <div className="col-50">
                    <h2>"Awesome service and even better burgers!"</h2>
                    <p>@honeyb</p>
                </div>
            </div>
        </section>
    )
}

export default Review