import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import About from './pages/about/about';
import Shop from './pages/shop/shop';
import Notfound from './pages/notfound/notfound';
import Contact from './pages/contact/contact';
import Delivery from './pages/delivery/delivery';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Scrolltotoponurlchange from './components/scrolltotoponurlchange/scrolltotoponurlchange';
import Cart from './pages/cart/cart';
import Checkout from './pages/checkout/checkout';

export const CartContext = React.createContext();

function App() {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    if (storedCart.length > 0) {
      setCart(storedCart);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));

    const totalAmount = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotal(totalAmount);
  }, [cart]);

  const addToCart = (item) => {
    const existingItem = cart.find((cartItem) => cartItem.name === item.name);

    if (existingItem) {
      const updatedCart = cart.map((cartItem) =>
        cartItem.name === item.name ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      );
      setCart(updatedCart);
    } else {
      setCart((prevCart) => [...prevCart, { ...item, quantity: 1 }]);
    }
  };

  const removeFromCart = (itemName) => {
    setCart((prevCart) => prevCart.filter((item) => item.name !== itemName));
  };

  const updateItemCount = (itemName, newCount) => {
    const updatedCart = cart.map((item) => {
      if (item.name === itemName) {
        return { ...item, quantity: newCount };
      }
      return item;
    });

    setCart(updatedCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <div>
      <BrowserRouter>
        <Scrolltotoponurlchange />
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateItemCount, clearCart, total }} >
          <Header />
          <Routes>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/delivery' element={<Delivery />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
          <Footer />
        </CartContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
