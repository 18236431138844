import React, { useEffect, useState } from 'react';
import './footer.scss'
import creamtop from "../../images/cream_top_wave_01.png";
import redtop from "../../images/red_top_wave_01.png";
import { Link, useLocation } from 'react-router-dom';
import { FaMapMarkerAlt } from "react-icons/fa";
import { LuClock3 } from "react-icons/lu";
import { FaShoppingBag } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import logo from '../../images/logo.png'
import instagram1 from '../../images/instagram_01.jpg'
import instagram2 from '../../images/instagram_02.jpg'
import instagram3 from '../../images/instagram_03.jpg'
import instagram4 from '../../images/instagram_04.jpg'
import instagram5 from '../../images/instagram_05.jpg'
import instagram6 from '../../images/instagram_06.jpg'


const Footer = () => {
  const currentYear = new Date().getFullYear();

  const location = useLocation();
  const [topImage, setTopImage] = useState(creamtop);

  useEffect(() => {
    if (location.pathname === '/contact' || location.pathname === '/delivery') {
      setTopImage(redtop);
    } else {
      setTopImage(creamtop);
    }
  }, [location.pathname]);

  return (
    <footer>
      {/* <img src={window.location.pathname === '/contact' || window.location.pathname === '/delivery' ? redtop : creamtop} alt='top'/> */}
      <img src={topImage} className='cream-top' alt="top" />
      <div className="wrapper-container">
        <div className="stay__informed">
          <img className='logo' src={logo} alt="" />
          <h2>Stay informed about special offers</h2>
          <h3>Subscribe to the newsleter</h3>
          <div className="email">
            <input type="email" placeholder='Enter Your Email' />
            <button type='submit'>Subscribe</button>
          </div>
        </div>
        <div className="footer__content">
          <div className="footer__top">
            <div className="row">
              <div className="col-25">
                <h3 className='footer__titles'>let's get together</h3>
                <ul>
                  <li>
                    <div>
                      <FaMapMarkerAlt />
                      60 East 65th Street, NY
                    </div>
                  </li>
                  <li>
                    <div>
                      <LuClock3 />
                      <span>Mon - Sat</span>: 11AM - 11PM
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaShoppingBag />
                      <span>Mon - Sat</span>: 01PM - 10PM
                    </div>
                  </li>
                  <li>
                    <div>
                      <FaPhoneVolume />
                      <span>1-800-700-600</span>
                    </div>
                  </li>
                </ul>
                <div className='social'>
                  <Link to='https://www.facebook.com/' target="_blank"><FaFacebookF /></Link>
                  <Link to='https://twitter.com/' target="_blank"><FaTwitter /></Link>
                  <Link to='https://www.instagram.com/' target="_blank"><FaInstagram /></Link>
                </div>
              </div>
              <div className="col-25">
                <h3 className='footer__titles'>about us</h3>
                <div className="quick__links">
                  <Link to='/about'>Our Story</Link>
                  <Link to='/about'>Our Team</Link>
                  <Link to='/about'>Our Food</Link>
                  <Link to='/about'>Health Measures</Link>
                  <Link to='/about'>Job Opportunities</Link>
                </div>
              </div>
              <div className="col-25">
                <h3 className='footer__titles'>quick menu</h3>
                <div className="quick__links">
                  <Link to='/shop'>Beef Burgers</Link>
                  <Link to='/shop'>Chicken Burgers</Link>
                  <Link to='/shop'>Plant-Based Burgers</Link>
                  <Link to='/shop'>Sides</Link>
                  <Link to='/shop'>Drinks</Link>
                </div>
              </div>
              <div className="col-25">
                <h3 className='footer__titles'>instagram</h3>
                <div className='images'>
                  <div className='image__scale'>
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram1} alt="instagram post" /></Link>
                  </div>
                  <div className="image__scale">
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram2} alt="instagram post" /></Link>
                  </div>
                  <div className="image__scale">
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram3} alt="instagram post" /></Link>
                  </div>
                  <div className="image__scale">
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram4} alt="instagram post" /></Link>
                  </div>
                  <div className="image__scale">
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram5} alt="instagram post" /></Link>
                  </div>
                  <div className="image__scale">
                    <Link to='https://www.instagram.com/' target="_blank"><img src={instagram6} alt="instagram post" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p className='copyright'>&#169; {currentYear} All rights reserved</p>
          <div className='policy__menu'>
            <Link to='/about'>About</Link>
            <Link to='/delivery'>Delivery</Link>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer