import React from 'react'
import './notfound.scss'
import { Link } from 'react-router-dom'

const Notfound = () => {
  return (
    <div className='page-404'>
      <div className="nopage">
        <h1>Page not found</h1>
        <p>We can't seem to find the page you're looking for.</p>
        <Link to='/'>Go back home</Link>
      </div>
    </div>
  )
}

export default Notfound