import React, { useContext } from 'react'
import './delivery.scss'
import creambottom from "../../images/cream_bottom_wave_01.png"
import Shipping from '../../components/shipping/shipping'
import inner_logo_1 from '../../images/inner_logo_01.jpg'
import inner_logo_2 from '../../images/inner_logo_02.jpg'
import inner_logo_3 from '../../images/inner_logo_03.jpg'
import inner_logo_4 from '../../images/inner_logo_04.jpg'
import redbottom from "../../images/red_bottom.png"
import { data } from '../../data'
import { CartContext } from '../../App'
import { Slide, ToastContainer, toast } from 'react-toastify'

const Delivery = () => {

  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(item);
    toast.success(`${item.name} added to your cart!`, {
      position: "bottom-right"
    });
  }

  return (
    <section className="delivery">
      <div className="delivery__hero">
        <div className="delivery__title">
          <div>
            <h1>Enjoy Eatsy Burgers <br /> at home!</h1>
            <p>You can order until 10PM</p>
          </div>
        </div>
        <img src={creambottom} className="creambottom" alt="creambottom" />
      </div>
      <Shipping />
      <div className="other__options">
        <div className="wrapper-container">
          <h3>Other Delivery Options</h3>
          <div className="delivery__cards">
            <div className="img__card">
              <img src={inner_logo_1} alt="inner" />
            </div>
            <div className="img__card">
              <img src={inner_logo_2} alt="inner" />
            </div>
            <div className="img__card">
              <img src={inner_logo_3} alt="inner" />
            </div>
            <div className="img__card">
              <img src={inner_logo_4} alt="inner" />
            </div>
          </div>
        </div>
        <img src={redbottom} className='redbottom' alt="redbottom" />
      </div>
      <div className="trends">
        <h1>Trending</h1>
        <div className="wrapper-container">
          <div className="row">
            {
              data.filter(item => item.category === "Beef" || item.category === "Vegan" || item.category === "Meals").slice(0, 4).map((item, index) => (
                <div className="col-25" key={index}>
                  <div className="mini__card">
                    <h4>{item.category}</h4>
                    <h3>{item.name}</h3>
                    <img src={item.image} alt="product" />
                    <div className='flex space-between'>
                      <div>
                        <h5>${item.price.toFixed(2)}</h5>
                        <span>{item.calories}</span>
                      </div>
                      <div className='add'>
                        <button type='button' onClick={() => handleAddToCart(item)}>+</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </section>
  )
}

export default Delivery