import React, { useState } from 'react'
import './contact.scss'
import creambottom from "../../images/cream_bottom_wave_01.png"
import Availability from '../../components/availability/availability';
import Follows from '../../components/follows/follows';
import { FaChevronRight } from "react-icons/fa";
import { questions } from '../../data';


const Contact = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section className="contact">
            <div className="contact__hero">
                <div className="contact__title">
                    <div>
                        <h1>Let's Get Together</h1>
                        <p className='text'>We strive to elevate the burger experience</p>
                    </div>
                </div>
                <img src={creambottom} className="creambottom" alt="" />
            </div>
            <Availability />
            <Follows />
            <div className="wrapper-container">
                <h1 className='contact__title'>Have a question?</h1>
                <p className='paragraph__title'>We love to hear from our guests!</p>
                <div className="row">
                    <div className="col-60">
                        <div className='questions'>
                            {questions.map((question, index) => (
                                <div key={index}>
                                    <div className={`flex question__title ${activeIndex === index ? 'active__arrow' : ''}`} onClick={() => handleClick(index)}>
                                        <FaChevronRight />
                                        <h2>{question.title}</h2>
                                    </div>
                                    <p className={activeIndex === index ? 'active__text' : 'text'}>{question.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-40">
                        <div className="personal__card">
                            <input type="name" placeholder='Name' />
                            <input type="email" placeholder='E-mail' />
                            <input type="subject" placeholder='Subject' />
                            <textarea name="textarea" id="" cols="30" rows="10" placeholder='Your Question/Suggestion'></textarea>
                            <button>Send a message</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact