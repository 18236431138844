import React from 'react'
import './shipping.scss';
import delivery from "../../images/home_01_delivery.png"
import stayhome from "../../images/stay_home.png"
import burger from "../../images/burger.png"
import motorcycle from "../../images/motorcycle.png"
import enjoy from "../../images/enjoy.png"


const Shipping = () => {
    return (
        <section className='shipping'>
            <div className='start'>
                <div>
                    <img className='takeAway' src={delivery} alt="" />
                </div>
                <div className='content'>
                    <img src={stayhome} alt="" />
                    <h1>Free delivery 7 days a week</h1>
                    <div>
                        <div className='flex'>
                            <div className='ico'>
                                {/* <GiHamburger /> */}
                                <img src={burger} alt="burger" />
                            </div>
                            <div>
                                <h2>Choose Burger</h2>
                                <p>Sesame snaps tootsie roll dessert candy canes apple pie marzipan topping toffee croissant.</p>
                            </div>
                        </div>
                        <div className='stepline'></div>
                        <div className='flex'>
                            <div className='ico'>
                                {/* <FaMotorcycle /> */}
                                <img src={motorcycle} alt="motorcycle" />
                            </div>
                            <div>
                                <h2>Delivery or Takeaway</h2>
                                <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test.</p>
                            </div>
                        </div>
                        <div className='stepline'></div>
                        <div className='flex'>
                            <div className='ico'>
                                {/* <HiOutlineEmojiHappy /> */}
                                <img src={enjoy} alt="enjoy" />
                            </div>
                            <div>
                                <h2>Enjoy Burger</h2>
                                <p>Praesent interdum mollis neque purus sed diam integer, in egestas nulla eget pede.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img src={creamtop} alt="" className='creamtop' /> */}
        </section>
    )
}

export default Shipping