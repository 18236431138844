import React, { useContext, useState } from 'react';
import './shop.scss';
import creambottom from '../../images/cream_bottom_wave_01.png';
import { data } from '../../data';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { IoMdClose } from "react-icons/io";
import burger1 from '../../images/product_03-300x300.jpg';
import { Link } from 'react-router-dom';
import { CartContext } from '../../App';
import { Slide, ToastContainer, toast } from 'react-toastify';

const Shop = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { cart, clearCart, removeFromCart, total } = useContext(CartContext);

  const cartItemCount = cart.reduce((acc, item) => {
    if (!acc[item.name]) {
      acc[item.name] = 0;
    }
    acc[item.name]++;
    return acc;
  }, {});

  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(item);
    toast.success(`${item.name} added to your cart!` ,{
      position: "bottom-right"
    });
  }


  return (
    <section className='shop'>
      <div className='shop__hero'>
        <div className='hero__title'>
          <div>
            <h1>Order and Enjoy</h1>
            <p>Start your order and enjoy the tastiest burgers</p>
          </div>
        </div>
        <img src={creambottom} className='creambottom' alt='' />
      </div>
      <div className='products'>
        <div className='wrapper-container'>
          <p>Showing {indexOfFirstProduct + 1}-{indexOfLastProduct > data.length ? data.length : indexOfLastProduct} of {data.length} results</p>
          <div className='first row'>
            <div className='col-75 mobile-col'>
              <div className='second row'>
                {currentProducts.map((item, index) => (
                  <div className='col-33' key={index}>
                    <div className='card'>
                      <img src={item.image} alt='' />
                      <h4>{item.category}</h4>
                      <h3>{item.name}</h3>
                      <h4 className='price'>${item.price.toFixed(2)}</h4>
                      <button type='button' onClick={() => handleAddToCart(item)}>Add to cart</button>
                    </div>
                  </div>
                ))}
              </div>
              <div className='pagination'>
                {currentPage > 1 && (
                  <FaArrowLeftLong onClick={() => paginate(currentPage - 1)} />
                )}
                <div className='pages'>
                  {Array.from({ length: Math.ceil(data.length / productsPerPage) }, (_, i) => (
                    <span key={i} className={i + 1 === currentPage ? 'active-page' : ''} onClick={() => paginate(i + 1)}>{i + 1}</span>
                  ))}
                </div>
                {currentPage < Math.ceil(data.length / productsPerPage) && (
                  <FaArrowRightLong onClick={() => paginate(currentPage + 1)} />
                )}
              </div>
            </div>
            <div className='col-25 mobile-w'>
              <div className='row'>
                <div className='col-100'>
                  <div className="cart">
                    <h3>Cart</h3>
                    {cart.length > 0 ? (
                      <>
                        <ul>
                          {cart.map((item, index) => (
                            <li key={index}>
                              <div className="flex relative">
                                <img src={item.image} alt={item.name} className="cart-item-image"/>
                                <div className="justify-center">
                                  <div>
                                    <h3 className="product-title">{item.name}</h3>
                                    <h4 className="product-quantity">{item.quantity} x $ {(item.price).toFixed(2)}</h4>
                                  </div>
                                </div>
                                <IoMdClose onClick={() => removeFromCart(item.name)}/>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <p>No products in the cart</p>
                    )}
                    {cart.length > 0 ? (
                      <div className="total">
                        <div className="flex subtotal">
                          <p className='subtotal-paragraph'>Subtotal:</p>
                          <p className='subtotal-price'>${total}</p>
                        </div>
                        <div className="flex buttons">
                          <Link to='/cart'>View cart</Link>
                          <Link to='/checkout'>Checkout</Link>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </section >
  );
};

export default Shop;
