import burger1 from '../src/images/product_01-640x640.jpg'
import burger2 from '../src/images/product_02-640x640.jpg'
import burger3 from '../src/images/product_03-640x640.jpg'
import burger4 from '../src/images/product_05-300x300.jpg'
import burger5 from '../src/images/product_07-300x300.jpg'
import burger6 from '../src/images/product_09-640x640.jpg'
import burger8 from '../src/images/product_08-300x300.jpg'
import burger9 from '../src/images/product_06-300x300.jpg'
import burger10 from '../src/images/product_07-300x300.jpg'
import meals1 from '../src/images/product_10-300x300.jpg'
import pizza1 from '../src/images/product_19-300x300.jpg'
import pizza2 from '../src/images/product_25-300x300.jpg'
import burger7 from '../src/images/product_03-300x300.jpg'
import pizza3 from '../src/images/product_17-300x300.jpg'
import donut1 from '../src/images/product_donut_01-300x300.jpg'
import donut2 from '../src/images/product_donut_02-300x300.jpg'
import donut3 from '../src/images/product_donut_05-300x300.jpg'
// import donut4 from '../src/images/product_donut_06-300x300.jpg'
import donut5 from '../src/images/product_donut_04-300x300.jpg'
import pizza4 from '../src/images/product_20-1-300x300.jpg'
import pizza5 from '../src/images/product_15-300x300.jpg'
import pizza6 from '../src/images/product_11-300x300.jpg'
import pizza7 from '../src/images/product_22-300x300.jpg'
import pizza8 from '../src/images/product_19-1-300x300.jpg'
import pizza9 from '../src/images/product_23-300x300.jpg'

export const data = [
    {
        id: 1,
        category: "Vegan",
        name: "Vegan Cheeseurger",
        image: burger4,
        price: 8.00,
        calories: "220gr / 600cal"
    },
    {
        id: 2,
        category: "Glazed",
        name: "Chocolate Glazed",
        image: donut2,
        price: 10.00,
    },
    {
        id: 3,
        category: "Special offer",
        name: "Premium Garden Veggie",
        image: pizza8,
        price: 7.00,
    },
    {
        id: 4,
        category: "Pizza",
        name: "Buffalo Chicken",
        image: pizza3,
        price: 10.00,
    },
    {
        id: 5,
        category: "Glazed",
        name: "Lime Chocolate Ring",
        image: donut5,
        price: 10.00,
    },
    {
        id: 6,
        category: "Pizza",
        name: "Doppio Pepperoni",
        image: pizza5,
        price: 7.00,
    },
    {
        id: 7,
        category: "Beef",
        name: "Black Angus Burger",
        image: burger2,
        price: 12.00,
        calories: "220gr / 600cal"
    },
    {
        id: 8,
        category: "Beef",
        name: "Bacon Cheeseburger",
        image: burger3,
        price: 8.00,
        calories: "220gr / 600cal"
    },
    {
        id: 9,
        category: "Pizza",
        name: "Bacon Alfredo",
        image: pizza1,
        price: 8.00,
    },
    {
        id: 10,
        category: "Vegan",
        name: "ShroomBacon Burger",
        image: burger5,
        price: 11.00,
        calories: "220gr / 600cal"
    },
    {
        id: 11,
        category: "Beef",
        name: "Onion Cheeseburger",
        image: burger6,
        price: 9.00,
        calories: "240gr / 600cal"
    },
    {
        id: 12,
        category: "Pizza",
        name: "Bacon Spinach Alfredo",
        image: pizza2,
        price: 8.00,
    },
    {
        id: 13,
        category: "Glazed",
        name: "Caramel Glazed Cream",
        image: donut1,
        price: 12.00,
    },
    {
        id: 14,
        category: "Special offer",
        name: "Chicken-Bacon Parmesan",
        image: pizza4,
        price: 7.00,
    },
    {
        id: 15,
        category: "Glazed",
        name: "Chocolate M&M Sprinkles",
        image: donut3,
        price: 14.00,
    },
    {
        id: 16,
        category: "Beef",
        name: "Crazy Beef Tuna Burger",
        image: burger8,
        price: 13.00,
    },
    {
        id: 17,
        category: "Beef",
        name: "Bøfsandwich",
        image: burger7,
        price: 8.00,
    },
    {
        id: 18,
        category: "Pizza",
        name: "Margherita",
        image: pizza6,
        price: 6.00,
    },
    {
        id: 19,
        category: "Beef",
        name: "Bacon + Cheese + Green Burger",
        image: burger1,
        price: 6.00,
        calories: "220gr / 600cal"
    },
    {
        id: 20,
        category: "Fries",
        name: "Mexican Nacho Burger",
        image: burger9,
        price: 12.00,
    },
    {
        id: 21,
        category: "Juice",
        name: "OMG! Burger",
        image: burger10,
        price: 12.00,
    },
    {
        id: 22,
        category: "Meals",
        name: "6 mini Burger + 6 Pack Kraft Beer",
        image: meals1,
        price: 22.00,
    },
    {
        id: 23,
        category: "Pizza",
        name: "Ortolana",
        image: pizza7,
        price: 9.00,
    },
    {
        id: 24,
        category: "Pizza",
        name: "Sorriso",
        image: pizza9,
        price: 7.00,
    },
]

export const questions = [
    {
        id: 1,
        title: 'How Do I Order Food Online?',
        text: 'Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.'
    },
    {
        id: 2,
        title: 'What if There’s An Issue With My Online Order?',
        text: 'Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.'
    },
    {
        id: 3,
        title: 'Can I Change My Order After I Submit It?',
        text: 'Unfortunately, once the order is placed it’s sent to our kitchen to begin preparation. We are unable to accept modifications or cancel the order. If you want to add to the order, please place an additional separate order.'
    },
    {
        id: 4,
        title: 'How Long Will It Take To Receive My Delivery Order?',
        text: 'Distinctively exploit optimal alignments for intuitive bandwidth. Quickly coordinate e-business applications through revolutionary catalysts for change. Seamlessly underwhelm optimal testing procedures whereas bricks-and-clicks processes.'
    },
    {
        id: 5,
        title: 'What If My Delivery Order Is Wrong?',
        text: 'Quickly aggregate B2B users and worldwide potentialities. Progressively plagiarize resource-leveling e-commerce through resource-leveling core competencies. Dramatically mesh low-risk high-yield alignments before transparent e-tailers.'
    },
    {
        id: 6,
        title: 'I Have Suggestion, Who Can I Contact?',
        text: 'Appropriately empower dynamic leadership skills after business portals. Globally myocardinate interactive supply chains with distinctive quality vectors. Globally revolutionize global sources through interoperable services.'
    },
]