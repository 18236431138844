import React, { useContext } from 'react';
import './home.scss';
import burger from '../../images/floating_burger_01.png';
// import leftfries from '../../images/floating_fries_01.png';
// import rightfries from '../../images/floating_fries_02.png';
import { data } from '../../data';

import Slider from '../../components/slider/slider';
import Shipping from '../../components/shipping/shipping';
import Review from '../../components/review/review';
import Follows from '../../components/follows/follows';
import Availability from '../../components/availability/availability';
import { CartContext } from '../../App';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Home = () => {
  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (item) => {
    addToCart(item);
    toast.success(`${item.name} added to your cart!` ,{
      position: "bottom-right"
    });
  }

  return (
    <>
      <section className='home'>
        <h1>Delicious Burgers</h1>
        <div className='center'>
          <img src={burger} className='burger' alt="burger" />
        </div>
        {/* <img src={leftfries} className='leftfries' alt="" /> */}
        {/* <img src={rightfries} className='rightfries' alt="" /> */}
        <h2>Bestsellers</h2>
        <div className="wrapper-container">
          <div className="row">
            {data.filter(item => item.category === "Beef" || item.category === "Vegan" || item.category === "Meals").slice(0, 6).map((item, index) => (
              <div className="col-33" key={index}>
                <div className='card'>
                  <h4>{item.category}</h4>
                  <h3>{item.name}</h3>
                  <img src={item.image} alt="" />
                  <div className='flex space-between'>
                    <div>
                      <h5>${item.price.toFixed(2)}</h5>
                      <span>{item.calories}</span>
                    </div>
                    <div className='add'>
                      {/* <button type='submit' onClick={() => addToCart(item)}>+</button> */}
                      <button type='button' onClick={() => handleAddToCart(item)}>+</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer transition={Slide} />
      </section>
      <Slider />
      <Shipping />
      <Review />
      <Follows />
      <Availability />
    </>
  )
}

export default Home